<template>
  <div style="padding-bottom: 2.5rem">
    <div class="flexDiv">
      <b-overlay no-wrap :show="show" opacity="0.95"> </b-overlay>
      <div class="col-sm-6">
        <b-row>
          <b-form-group class="col-sm-12">
            <template #label>
              <label for="" class="mr-3"
                >Cliente
                <b-icon-search
                  class="ml-2"
                  variant="primary"
                  @click="searchCliente"
                ></b-icon-search
              ></label>
              <b-button
                size="sm"
                pill
                variant="primary"
                @click="listClientes"
                class="float-right"
                ><b-icon-arrow-repeat></b-icon-arrow-repeat
              ></b-button>

              <label
                v-if="liberacao.dataExpiracaoCertificado"
                class="contentCertificateExpiration"
              >
                <span>Certificado expira em: </span>
                <span class="timeExpiration">
                  {{ displayTimeExpirationCertificate() }}</span
                >
              </label>
            </template>
            <b-form-select
              :options="clientes"
              v-model="liberacao.clienteId"
              size="sm"
              value-field="uuid"
              text-field="nome"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            :class="`${enableCreateResponsible ? 'col-sm-7' : 'col-sm-12'}`"
          >
            <label for="" class="mr-3 mt-3">Software</label>
            <b-button
              size="sm"
              pill
              variant="primary"
              @click="listSoftware"
              class="float-right mt-2"
              ><b-icon-arrow-repeat></b-icon-arrow-repeat
            ></b-button>

            <b-button
              v-if="enableCreateResponsible"
              size="sm"
              variant="success"
              class="float-right mt-2 mr-2 d-flex align-items-center"
              @click="openModalCreateResponsible"
              ><b-icon icon="person-fill" />

              <p class="textResp">Responsável</p>
            </b-button>

            <b-form-select
              :options="softwares"
              size="sm"
              v-model="liberacao.softwareId"
              value-field="uuid"
              text-field="nomesoftware"
              ref="textInputSoftware"
            ></b-form-select>
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Licenças" class="col-sm-4">
            <b-form-input
              size="sm"
              v-model="liberacao.qtdlicencas"
              type="number"
            />
          </b-form-group>

          <b-form-group label="Bloqueado" class="col-sm-4">
            <b-form-select
              :options="bloqueado"
              v-model="liberacao.bloqueado"
              size="sm"
              class=""
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Termos e Condições" class="col-sm-4">
            <b-form-select
              disabled
              :options="contrato"
              v-model="liberacao.contrato"
              size="sm"
              class=""
            ></b-form-select>
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Inicio de Uso" class="col-sm-6">
            <b-input
              v-model="liberacao.datainiciouso"
              size="sm"
              type="date"
            ></b-input>
          </b-form-group>

          <b-form-group label="Fim de Uso" class="col-sm-6">
            <b-input
              v-model="liberacao.datafimuso"
              size="sm"
              type="date"
            ></b-input>
          </b-form-group>
        </b-row>

        <b-row>
          <b-form-group label="Mensagem" class="col-sm-12">
            <b-form-textarea
              v-model="liberacao.messagem"
              size="sm"
              rows="3"
            ></b-form-textarea>
            <label for="">Mensagem Interna</label>
            <b-form-textarea
              v-model="liberacao.mensageminterna"
              size="sm"
              rows="3"
            ></b-form-textarea>
          </b-form-group>
        </b-row>
      </div>

      <div class="col-sm-6 chaves">
        <b-form-group class="col-sm-12">
          <template #label>
            <label for="">Chave</label>
            <b-button
              variant="primary"
              class="float-right"
              size="sm"
              @click="addChave"
              >Adicionar Chave</b-button
            >
          </template>
          <b-form-input v-model="liberacao.chaves" size="sm"></b-form-input>
        </b-form-group>

        <b-card class="mt-4 ml-3 cardTable tableResponsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">Chave</th>
                <th scope="col">Excluir</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="chave in chaves" :key="chave.chave">
                <td>{{ chave.chave }}</td>
                <td>
                  <b-button
                    variant="primary"
                    pill
                    size="sm"
                    @click="excluirChave(chave.chave)"
                    >Excluir</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <Table :fields="fields" :keys="keys" :dataProps="chaves">
            <template #buttonAcoes3="{ chave }">
              <b-button
                variant="primary"
                pill
                size="sm"
                @click="excluirChave(chave)"
                >Excluir</b-button
              >
            </template>
          </Table> -->
        </b-card>

        <b-row class="col-sm-12 mt-5">
          <b-form-group class="col-sm-4" label="Login">
            <b-form-input
              class="col-sm-12"
              size="sm"
              v-model="dadosLogin.login"
              placeholder="Example@gmail.com"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-sm-4" label="Senha">
            <b-form-input
              class="col-sm-12"
              size="sm"
              v-model="dadosLogin.senha"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="col-sm-3"
            label-for="habilitar-mtq"
            label="Habilitar MT2"
          >
            <b-form-checkbox
              id="habilitar-mtq"
              v-model="dadosLogin.habilitarMtq"
              name="habilitar-mtq"
              :checked="dadosLogin.habilitarMtq"
              class="text-center"
            >
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="col-sm-4 mt-4">
            <b-button
              style="margin-top: 5px"
              variant="primary"
              size="sm"
              @click="addUsuario"
              >Adicionar Usuário</b-button
            >
          </b-form-group>
        </b-row>
        <b-card class="mt-4 ml-3 cardTable tableResponsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Usuário</th>
                <th>MT2</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in usuarioLogin" :key="user.uuid">
                <td>{{ user.login }}</td>
                <td>{{ user.habilitarMtq ? "Sim" : "Não" }}</td>
                <td>
                  <b-button
                    variant="primary"
                    pill
                    size="sm"
                    @click="excluirLogin(user.uuid)"
                    >Excluir</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </div>
    </div>
    <modalCliente @clienteSelecionado="liberacao.clienteId = $event" />
    <ModalCreateResponsible :idEmpresa="liberacao.clienteId">
      <template #createResponsible />
      <template #searchResponsible />
    </ModalCreateResponsible>

    <div style="padding: 1rem" v-if="enableIntegrations">
      <div style="border-bottom: 1px solid rgb(0 0 0 / 16%)">
        <p style="margin-bottom: 0.3rem">Integrações</p>
      </div>

      <div class="cardIntegrations">
        <div style="width: 100%">
          <section class="containerIntegrations">
            <div class="machinesIntegrations">
              <p>Maquininhas de cartões</p>

              <div class="contentMachines">
                <label
                  v-for="machine in liberacao.integracoes.cardMachines"
                  class="labelMachine"
                  :for="`input-${machine.text}`"
                  :key="machine.text"
                >
                  {{ machine.text }}
                  <input
                    v-model="machine.value"
                    :id="`input-${machine.text}`"
                    type="checkbox"
                  />
                </label>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        background-color: white;
        box-shadow: 0 -2px 2px 0 #00000040;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 200;
        padding: 0.5rem 1rem;
      "
    >
      <b-button style="width: 80px" variant="primary" size="sm" @click="save"
        >Salvar</b-button
      >
      <b-button style="width: 80px" class="ml-4" size="sm" @click="limpar"
        >Novo</b-button
      >
    </div>
  </div>
</template>

<script>
import { http } from "../../services/config";
import modalCliente from "../../components/Modals/modal-search-cliente.vue";
import ModalCreateResponsible from "../Modals/modal-create-responsible.vue";
// import Table from "../../components/Table/Table.vue";
import { mapState } from "vuex";
export default {
  components: {
    // Table,
    modalCliente,
    ModalCreateResponsible,
  },

  props: {
    liberacaoProps: {
      type: Object,
    },
  },

  computed: {
    ...mapState({
      clientes: (state) => state.clientes,
      softwares: (state) => state.softwares,
    }),

    enableCreateResponsible() {
      if (
        this.liberacao.softwareId === "723a7430-508d-4319-980c-df5fc6134f93"
      ) {
        return true;
      } else {
        return false;
      }
    },
    enableIntegrations() {
      return this.liberacao.softwareId ===
        "08e9327e-f8b0-4039-9e8d-bd973cbf0d5c"
        ? true
        : false;
    },
  },
  created() {
    this.show = true;
    this.listClientes();
    this.listSoftware();
    this.show = false;
  },
  data() {
    return {
      show: false,
      bloqueado: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      contrato: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      keys: [{ key: "chave" }],
      fields: [{ titulo: "Chave" }, { titulo: "Excluir" }],
      chaves: [],
      liberacao: {
        uuid: "",
        clienteId: "",
        softwareId: "",
        qtdlicencas: "",
        bloqueado: false,
        contrato: false,
        datainiciouso: "",
        datafimuso: "",
        messagem: "",
        mensageminterna: "",
        chaves: "",
        integracoes: {
          cardMachines: [
            {
              text: "STONE",
              value: false,
            },
          ],
        },
      },
      dadosLogin: {
        login: "",
        senha: "",
        clienteId: "",
        habilitarMtq: false,
      },
      usuarioLogin: [],
      newSoftwareId: null,
    };
  },
  methods: {
    searchCliente() {
      this.$bvModal.show("modal-1");
    },
    limpar() {
      this.chaves = [];
      this.liberacao = {
        uuid: "",
        clienteId: "",
        softwareId: "",
        qtdlicencas: "",
        bloqueado: false,
        datainiciouso: "",
        datafimuso: "",
        messagem: "",
        mensageminterna: "",
        chaves: "",
        contrato: false,
        integracoes: {
          cardMachines: [
            {
              text: "STONE",
              value: false,
            },
          ],
        },
      };

      this.$emit("clearCustomer");
    },
    async excluirChave(chave) {
      try {
        if (chave != "") {
          const url = `/activations/key/remove/${this.liberacao.uuid}`;
          await http.put(url, { chave: chave });
          this.$swal("Liberações", "Chave Deletada com Sucesso", "success");
          this.$emit("chaveAdicionada", this.liberacao.uuid);
        }
      } catch (error) {
        this.$swal("Liberações", "Erro ao Deletar Chave", "error");
      }
    },
    async addChave() {
      try {
        if (this.liberacao.uuid === "") {
          return this.$toast.open({
            message: "Selecione um Cliente",
            type: "info",
            // all of other options may go here
          });
        }
        if (this.chaves.length >= this.liberacao.qtdlicencas) {
          return this.$toast.open({
            message: "Cliente Não possui mais licenças",
            type: "info",
            // all of other options may go here
          });
        }
        const url = `/activations/key/add/${this.liberacao.uuid}`;
        await http.put(url, { chave: this.liberacao.chaves });
        this.$swal("Liberações", "Chave Adicionada com Sucesso", "success");
        this.$emit("chaveAdicionada", this.liberacao.uuid);
        this.liberacao.chaves = "";
      } catch (error) {
        if (error.response.status === 409) {
          return this.$toast.open({
            message: "Chave já Existente",
            type: "info",
            // all of other options may go here
          });
        }
        this.$swal("Liberações", "Erro ao adicionar Chave", "error");
      }
    },

    async save() {
      if (
        this.liberacao.clienteId === "" ||
        this.liberacao.softwareId === "" ||
        this.liberacao.qtdlicencas === "" ||
        this.liberacao.datainiciouso === "" ||
        this.liberacao.datafimuso === ""
      ) {
        this.$swal(
          "Liberações",
          "Verifique se todos os campos estão preenchidos",
          "info"
        );
        return;
      }
      try {
        if (this.liberacao.uuid) {
          this.liberacao.qtdlicencas = parseInt(this.liberacao.qtdlicencas);
          await http.put(`/activations/${this.liberacao.uuid}`, this.liberacao);
          this.$swal(
            "Liberações",
            "Liberação Atualizada com Sucesso",
            "success"
          );
          return;
        }
        this.liberacao.qtdlicencas = parseInt(this.liberacao.qtdlicencas);
        const { data } = await http.post("/activations", this.liberacao);
        this.liberacao.uuid = data.uuid;
        this.$swal("Liberações", "Liberação Cadastrada com Sucesso", "success");
      } catch (error) {
        if (error.response.status === 422) {
          this.$swal(
            "Liberações",
            "Ocorreu um erro com os dados de liberação",
            "info"
          );
          return;
        }
        if (error.response.status === 409) {
          this.$swal(
            "Liberações",
            "Cliente ja possui uma liberação para este Software",
            "info"
          );
          return;
        }
        this.$swal(
          "Liberações",
          "Ocorreu um erro ao realizar liberação",
          "error"
        );
      }
    },

    async listClientes() {
      try {
        const url = `/customers/list/combobox`;
        const { data } = await http.get(url);
        this.$store.commit("clientes", data);
      } catch (error) {
        this.$toast.open({
          message: "erro na combobox",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async listSoftware() {
      try {
        const url = `/softwares/list/combobox`;
        const { data } = await http.get(url);
        this.$store.commit("softwares", data);
      } catch (error) {
        this.$toast.open({
          message: "erro na combobox",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async excluirLogin(clienteId) {
      try {
        await http.delete(`/web/${clienteId}`);
        this.getUsuario();
        return this.$toast.open({
          message: "Login removido",
          type: "success",
        });
      } catch (error) {
        console.log(error);
      }
    },

    validacoesLoginSenha() {
      if (this.liberacao.clienteId === "") {
        this.$toast.open({
          message: "Selecione um Cliente",
          type: "warning",
        });

        return false;
      } else if (this.dadosLogin.login === "" || this.dadosLogin.senha === "") {
        this.$toast.open({
          message: "Preecha os campos login e senha",
          type: "warning",
        });

        return false;
      }

      return true;
    },

    async addUsuario() {
      try {
        if (!this.validacoesLoginSenha()) return;

        const software = this.softwares.find(
          (soft) => soft.uuid === this.liberacao.softwareId
        )?.nomesoftware;

        this.dadosLogin.clienteId = this.liberacaoProps.clienteId;
        await http.post("/web", this.dadosLogin, {
          headers: {
            software,
          },
        });
        this.getUsuario();

        this.clear();
        return this.$toast.open({
          message: "Login criado",
          type: "success",
        });
      } catch (error) {
        if (error.response.status === 409) {
          return this.$toast.open({
            message: "Login já existente",
            type: "warning",
            // all of other options may go here
          });
        } else if (
          error.response.data &&
          error.response.data.message === "LIMIT_USERS"
        ) {
          return this.$toast.open({
            message: "Limite de usuários atingido",
            type: "warning",
          });
        } else if (
          error?.response?.status === 422 &&
          error?.response?.data?.message.includes(
            "ERROR_NAME: not_found_software_plan"
          )
        ) {
          const formattedMessage = error.response.data.message
            .split("-")[1]
            .trim();
          this.$toast.open({
            message: `${formattedMessage}`,
            type: "info",
          });
        }
      }
    },

    async getUsuario() {
      try {
        const { data } = await http.get(
          `/web/users?activationId=${this.liberacao.uuid}`
        );
        this.usuarioLogin = data;
      } catch (error) {
        console.log(error);
      }
    },

    openModalCreateResponsible() {
      this.$bvModal.show("modalCreateResponsible");
    },

    displayTimeExpirationCertificate() {
      if (this.liberacao.dataExpiracaoCertificado) {
        return `${this.liberacao.dataExpiracaoCertificado} ${
          this.liberacao.dataExpiracaoCertificado > 1 ? "dias" : "dia"
        }`;
      }

      return null;
    },

    clear() {
      this.dadosLogin = {
        login: "",
        senha: "",
        clienteId: "",
        habilitarMtq: false,
      };
    },
  },

  watch: {
    liberacaoProps() {
      this.chaves = this.liberacaoProps.chaves;
      delete this.liberacaoProps.chaves;

      Object.assign(this.liberacao, this.liberacaoProps);
      if (!this.liberacao.integracoes) {
        this.liberacao.integracoes = {
          cardMachines: [
            {
              text: "STONE",
              value: false,
            },
          ],
        };
      }

      this.dadosLogin.clienteId = this.liberacaoProps.clienteId;
      this.getUsuario();
    },
  },
};
</script>

<style scoped>
.textResp {
  margin: 0;
}

@media (max-width: 700px) {
  .flexDiv {
    width: 100%;
  }
  .chaves {
    margin-top: 30px;
  }
}

.tableResponsive {
  overflow-x: auto;
}

.cardTable {
  max-height: 200px;
  overflow-y: scroll;
}
.flexDiv {
  display: flex;
  flex-wrap: wrap;
}

.contentCertificateExpiration {
  background-color: #4e4e74;
  padding: 0.25rem;
  border-radius: 5px;
  color: white;
}

.timeExpiration {
  background-color: #ae3838;
  border-radius: 5px;
  padding: 0.1rem;
}

.cardIntegrations {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.contentTitleIntegrations {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.containerIntegrations {
  display: flex;
  width: 100%;
  padding-top: 15px;
  gap: 2rem;
}

.machinesIntegrations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem 1rem 1rem;
  border-radius: 5px;
  background-color: #a1a1a138;
}

.machinesIntegrations p {
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.contentMachines {
  width: 300px;
  max-width: 300px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
}

.labelMachine {
  padding: 0.3rem;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 #0000008b;
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  user-select: none;
  cursor: pointer;
}

.labelMachine input {
  width: 18px;
  height: 18px;
}
</style>
