<template>
  <div>
    <b-form class="col-sm-12 row">
      <b-input v-model="user.uuid" hidden></b-input>
      <b-form-group label="Login" class="col-sm-5">
        <b-form-input
          type="text"
          placeholder="Login"
          size="sm"
          v-model="user.username"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Senha" class="col-sm-4">
        <b-form-input
          type="password"
          placeholder="Senha"
          size="sm"
          v-model="user.password"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Email" class="col-sm-4">
        <b-form-input
          type="text"
          placeholder="Email"
          size="sm"
          v-model="user.email"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Tipo Suporte" class="col-sm-3">
        <b-form-select
          :options="setor"
          size="sm"
          v-model="user.setor"
        ></b-form-select>
      </b-form-group>
    </b-form>
    <b-button class="ml-2 mr-2" @click="save" size="sm" variant="info"
      >Salvar</b-button
    >
    <b-button class="ml-2 mr-2" @click="limpar" size="sm" variant="info"
      >Novo</b-button
    >
  </div>
</template>

<script>
import { http } from "../../services/config";
export default {
  props:{
    userSelect:{
      type: Object
    }
  },
  data() {
    return {
      setor: [
        { text: "Suporte", value: "Suporte" },
        { text: "ADM", value: "Adm" },
        { text: "Financeiro", value: "Financeiro" },
        { text: "Vendas", value: "Vendas" },
      ],
      user: {
        uuid: "",
        username: "",
        email: "",
        setor: "",
        password: "",
      },
    };
  },
  methods: {
    limpar() {
      this.user = {
        uuid: "",
        username: "",
        email: "",
        setor: "",
        password: "",
      };
    },
    async save() {
      try {
        if (this.user.uuid) {
          await http.put(`users/${this.user.uuid}`, this.user);
          this.$swal("Usuário", "Usuário Atualizado com Sucesso", "success");
          return;
        }
        const { data } = await http.post("users", this.user);
        this.user.uuid = data.uuid;
        this.$swal("Usuário", "Usuário Cadastrado com Sucesso", "success");
      } catch (error) {
        if (error.response.data.erros.length > 0) {
          error.response.data.erros.map((el) => {
            if (Object.keys(el).toString() === "username") {
              this.$toast.open({
                message: "Login do Usuário : " + el.username,
                type: "error",
                // all of other options may go here
              });
            }
            if (Object.keys(el).toString() === "password") {
              this.$toast.open({
                message: "Senha : " + el.password,
                type: "error",
                // all of other options may go here
              });
            }
            if (Object.keys(el).toString() === "email") {
              this.$toast.open({
                message: "Email : " + el.email,
                type: "error",
                // all of other options may go here
              });
            }

            if (Object.keys(el).toString() === "setor") {
              this.$toast.open({
                message: "Setor: " + el.setor,
                type: "error",
                // all of other options may go here
              });
            }
          });
          return;
        }
        this.$swal("Usuário", "Erro ao cadastrar Usuário", "error");
      }
    },
  },

  watch:{
    userSelect(){
      Object.assign(this.user, this.userSelect)
    }
  }
};
</script>

<style>
</style>