import * as mutationsTypes from './mutations-types'

export default {
    [mutationsTypes.uuidCliente](state, uuidCliente){
        state.uuidCliente = uuidCliente
    },

    [mutationsTypes.clientes](state, clientes){
        state.clientes = []
        state.clientes = clientes
    }, 

    [mutationsTypes.softwares](state, softwares){
        state.softwares = []
        state.softwares = softwares
    }
}