<template>
  <div>
    <div class="flexDiv">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          :class="
            !verifyExitsCertificate
              ? 'cardCertificate'
              : 'cardCertificate cardCertificateWithShadow'
          "
        >
          <span class="contentTitleCertificate">
            <p>Informações do certificado</p>
          </span>

          <b-row
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-if="!verifyExitsCertificate"
          >
            <div
              class="col-sm-5 col-md-4 col-lg-4 col-xl-5"
              style="margin-top: 30px"
            >
              <b-form-file
                size="sm"
                browse-text="Buscar"
                placeholder="Selecione o certificado..."
                v-model="payload.certificate"
                class="mb-2"
              />
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-5">
              <div class="contentInputPassword">
                <b-form-input
                  size="sm"
                  :type="typeInput"
                  placeholder="Senha do certificado..."
                  autocomplete="new-password"
                  v-model="payload.password"
                />
                <span class="iconEye" @click="handleViewPassword">
                  <b-icon-eye-slash-fill v-if="viewPassword" />
                  <b-icon-eye-fill v-else />
                </span>
              </div>
            </div>

            <div class="contentBtnFile col-sm-1 col-md-1 col-lg-1 col-xl-2">
              <div>
                <b-button
                  @click="uploadCertificate"
                  variant="primary"
                  class="float-right"
                  size="sm"
                  >Salvar</b-button
                >
              </div>
            </div>
          </b-row>

          <div class="contentDataCertificate" v-else>
            <div class="certificateSpecification">
              <span>Emissora:</span>
              <p>{{ dataCustomer.certificadoEmissora }}</p>
            </div>

            <div class="certificateSpecification">
              <span>Valido de:</span>
              <p>{{ formatDate(dataCustomer.certificadoValidoDe) }}</p>
            </div>

            <div class="certificateSpecification">
              <span>Até:</span>
              <p>{{ formatDate(dataCustomer.certificadoValidoAte) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../services/config";

export default {
  components: {},

  props: {
    idCustomer: {
      type: String,
    },
    dataCustomer: {
      type: Object,
    },
  },

  computed: {
    verifyExitsCertificate() {
      if (this.dataCustomer?.certificadoEmissora) return true;

      return false;
    },
  },
  created() {},
  data() {
    return {
      payload: {
        password: null,
        certificate: null,
      },
      typeInput: "password",
      viewPassword: false,
    };
  },
  methods: {
    async uploadCertificate() {
      if (!this.idCustomer) {
        return this.$swal(
          "Certificado",
          "Para vincular o certificado é necessário selecionar um cliente",
          "info"
        );
      } else if (!this.payload.certificate || !this.payload.password) {
        return this.$swal(
          "Certificado",
          "É necessário informar o certificado e a senha",
          "info"
        );
      }

      const formData = await this.handleCertificate();
      const { data } = await http.post(
        `/activations/upload/client/certificate/${this.idCustomer}?senha=${this.payload.password}`,
        formData
      );

      if (data && Object.keys(data).length) {
        this.dataCustomer.certificadoEmissora = data.issuer;
        this.dataCustomer.certificadoValidoDe = data.validFrom;
        this.dataCustomer.certificadoValidoAte = data.validTo;
      }
    },
    async handleCertificate() {
      if (this.payload.certificate) {
        const formData = new FormData();
        formData.append("certificate", this.payload.certificate);
        return formData;
      }
    },
    handleViewPassword() {
      this.viewPassword = !this.viewPassword;

      this.viewPassword
        ? (this.typeInput = "text")
        : (this.typeInput = "password");
    },
    formatDate(date) {
      if (date) {
        const newDate = date.split("T")[0].split("-").reverse().join("/");
        const time = date.split("T")[1].split(".")[0];

        return `${newDate} ${time}`;
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
.contentBtnFile {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
}

.contentInputPassword {
  display: flex;
  margin-top: 30px;
  position: relative;
}

.contentInputPassword .form-control {
  padding-right: 38px;
}

.iconEye {
  cursor: pointer;
  width: 35px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #e8ecef;
  border: solid 1px #c5c8cbbf;
  user-select: none;
}

.cardCertificate {
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.125);
  box-shadow: 0 0 2px 0 #0000004d;
}

.cardCertificateWithShadow {
  width: 350px !important;
}

.contentTitleCertificate {
  font-size: 14px;
  color: #666;
}

.contentDataCertificate {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  padding: 0 15px;
}

.certificateSpecification {
  display: flex;
  gap: 5px;
  color: #666;
  font-size: 13px;
}

.certificateSpecification span {
  color: rgba(0, 0, 255, 0.579);
}

p {
  margin: 0;
}

@media (max-width: 700px) {
  .flexDiv {
    width: 100%;
  }
}
</style>
