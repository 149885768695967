<template>
  <div>
    <MenuBar />

    <b-card no-body class="m-2 ">
      <b-tabs card v-model="tabIndex">
        <b-tab title="Agendar">
          <b-card>
            <FormAgendar
              :novo=novoAgendamento
              :agendamentoProps="agendamento"
              :horarios="horarios"
              @newAgendamento="novoAgendamento = !novoAgendamento"
            />
          </b-card>
        </b-tab>
        <b-tab title="Pesquisar Agendamentos" :disabled="setor === 'Adm' ? false : true">
          <SearchAgendamento :newAgendamento="novoAgendamento" @dataAgendamento="dataAgendamento" />
        </b-tab>
        <b-tab title="Suporte">
          <FormSuporte
            @refresh="novoAgendamento = !novoAgendamento"
            :novoAgendamento="novoAgendamento"
            @visualizaUuid="loadDateFormAgendamento($event)"
            @historicoEvent="loadDateFormAgendamento($event)"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import MenuBar from "../../components/menu.vue";
import SearchAgendamento from "../../components/Search/search-agendamentos.vue";
import FormAgendar from "../../components/forms/FormAgendar.vue";
import FormSuporte from "../../components/forms/FormSuporte.vue";
import { http } from "../../services/config";

export default {
  components: {
    MenuBar,
    FormAgendar,
    SearchAgendamento,
    FormSuporte,
  },
  data() {
    return {
      horarios: {},
      tabIndex: 0,
      agendamento: {},
      novoAgendamento: true,
      setor: ""
    };
  },

  created(){
    this.setor = sessionStorage.getItem("setor")
  },
  methods: {
    async loadDateFormAgendamento(uuid) {
      const url = `/schedules/${uuid}`;
      const { data } = await http.get(url);
      const dataAgen = data?.dataAgendamento?.split(" ")[0];
      const dataInicio = data?.dataInicioAtendimento?.split(" ")[0];
      const dataFim = data?.dataFimAtendimento?.split(" ")[0];
      this.horarios = {
        horaAgendamento: data?.dataAgendamento?.split(" ")[1],
        horaInicio: data?.dataInicioAtendimento?.split(" ")[1],
        horaFim: data?.dataFimAtendimento?.split(" ")[1],
      };
      data.dataAgendamento = dataAgen;
      data.dataFimAtendimento = dataFim;
      data.dataInicioAtendimento = dataInicio;
      this.agendamento = data;
      this.tabIndex = 0;
    },
    dataAgendamento(agendamento) {
      this.agendamento = agendamento.data;
      this.horarios = agendamento.horario
      this.tabIndex = 0;
    },
  },
};
</script>

<style>
.alertError {
  width: 100px;
}
</style>