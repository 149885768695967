<template>
  <div>
    <b-modal :id="idModal" size="lg" title="Clientes" @hidden="resetModal">
      <b-row class="col-sm-12">
        <b-form-group label="Pesquisar" class="col-sm-6">
          <b-input @dblclick="listaClientes" v-model="valuePesquisa" size="sm">
          </b-input>
        </b-form-group>
        <b-form-group label="Pesquisar" class="col-sm-6">
          <b-form-select
            v-model="colunaPesquisa"
            :options="tablesPesquisa"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-row>

      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">CNPJ/CPF</th>
            <th scope="col">Selecionar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cliente in clientes" :key="cliente.uuid">
            <td>{{ cliente.nome }}</td>
            <td>{{ cliente.cpfcnpj }}</td>
            <td>
              <b-button
                size="sm"
                variant="primary"
                @click="adicionar(cliente.uuid)"
                >Adicionar</b-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import { http } from "../../services/config";
export default {
  props: {
    idModal: {
      type: String,
      default: "modal-1",
    },
  },
  data() {
    return {
      tablesPesquisa: [
        { text: "Nome", value: "nome" },
        { text: "Cpf/Cnpj", value: "cpfcnpj" },
        { text: "E-mail", value: "email" },
      ],
      colunaPesquisa: "nome",
      valuePesquisa: "",
      clientes: [],
      historicoData: [],
      historicoDataLegado: [],
    };
  },

  components: {},
  created() {},
  methods: {
    resetModal() {
      this.colunaPesquisa = "";
      this.valuePesquisa = "";
      this.clientes = [];
    },

    adicionar(uuid) {
      this.$emit("clienteSelecionado", uuid);
      this.$bvModal.hide(this.idModal);
    },

    async historicoLegado2(cpfcnpj) {
      try {
        const urlLegado = `/gestao/legado/historico/${cpfcnpj}`;
        const { data } = await http.get(urlLegado);
        this.historicoDataLegado = data;

        this.historicoDataLegado.map((el) => {
          el.dataagendamento =
            el.dataagendamento === null
              ? ""
              : new Date(el.dataagendamento).toISOString().split("T")[0];
          el.datafimatendimento =
            el.datafimatendimento === null
              ? ""
              : new Date(el.datafimatendimento).toISOString().split("T")[0];
          el.datainicioatendimento =
            el.datainicioatendimento === null
              ? ""
              : new Date(el.datainicioatendimento).toISOString().split("T")[0];
        });
        this.$bvModal.show("modal-lg-history");
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Nenhum Histórico Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$toast.open({
          message: "Erro no historico legado",
          type: "info",
          // all of other options may go here
        });
      }
    },

    async historico2(cpfcnpj) {
      try {
        const url = `/schedules/search/historySchedules/${cpfcnpj}`;
        const { data } = await http.get(url);
        this.historicoData = data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$toast.open({
            message: "Nenhum Histórico Encontrado",
            type: "info",
            // all of other options may go here
          });
          return;
        }
        this.$swal("Agendamento", "Erro ao visualizar Historico", "error");
      }
    },

    async listaClientes() {
      try {
        const url = `/customers/search/pagination?${
          this.colunaPesquisa
        }=${encodeURI(this.valuePesquisa)}`;
        const { data } = await http.get(url);
        this.clientes = data;
      } catch (error) {
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Cliente Não Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$swal("Cliente", "Ocorreu um erro na pesquisa.", "error");
      }
    },
  },
};
</script>

<style>
</style>