<template>
  <div>
    <b-modal id="modal-info-clientes" size="xl" title="Info Cliente">
      <h6>Nome: {{ cliente.nome }}</h6>
      <h6>Cpf/Cnpj: {{ cliente.cpfcnpj }}</h6>
      <h6>Telefone: {{ cliente.telefone }}</h6>
      <h6>Celular: {{ cliente.celular }}</h6>
      <hr />
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        class="border"
      ></b-table>
    </b-modal>
  </div>
</template>

<script>
import { http } from "../../services/config";

export default {
  props: {
    idCliente: {
      type: String,
    },
  },
  data() {
    return {
      cliente: {},
      items: [],
      fields: [
        {
          key: "software",
          label: "Software",
        },
        {
          key: "qtdlicencas",
          label: "Licenças",
        },
        {
          key: "bloqueado",
          label: "Bloqueado",
        },
        {
          key: "datainiciouso",
          label: "Inico",
        },
        {
          key: "datafimuso",
          label: "Fim",
        },
        {
          key: "messagem",
          label: "Mensagem",
        },
        {
          key: "mensageminterna",
          label: "Mensagem interna",
        },
        {
          key: "vendedora",
          label: "Vendedora",
        },
        {
          key: "chaves",
          label: "Chaves",
        },
      ],
    };
  },
  components: {},
  created() {
    this.cliente = {};
    this.items = [];
  },
  watch: {
    idCliente() {
      if (this.idCliente) {
        this.cliente = {};
        this.items = [];
        this.listaCliente(this.idCliente);
      } else {
        this.cliente = {};
        this.items = [];
      }
    },
  },
  methods: {
    async listaCliente(id) {
      try {
        const { data } = await http.get(`/customers/${id}`);
        this.cliente = data;

        const responseActivations = await http.get(
          "/activations/search/actation/by/" + data.cpfcnpj
        );

        // bloqueado: false
        // chaves: "8BFE7F30B7F5E69B2EFB72BBBC0AC395"
        // clienteId: "00487af5-01bd-4b1d-93f1-5b456adadb33"
        // datafimuso: "2023-01-10T00:00:00.000Z"
        // datainiciouso: "2022-01-10T00:00:00.000Z"
        // mensageminterna: ""
        // messagem: ""
        // qtdlicencas: 10
        // softwareId: "0a2f962e-f241-4023-b919-a5fbb85749da"
        // uuid: "7513abf2-f1c4-49f5-8b75-18be86c7f50a"
        this.items = responseActivations.data.map((value) => {
          return {
            software: value.software.nomesoftware,
            qtdlicencas: value.qtdlicencas,
            bloqueado: value.bloqueado ? "Sim" : "Não",
            datainiciouso: value.datainiciouso,
            datafimuso: value.datafimuso,
            mensagem: value.mensagem,
            mensageminterna: value.mensageminterna,
            vendedora: value?.cliente?.user?.username ?? "",
            chaves: value?.chaves?.split(";")?.length ?? 0,
          };
        });
      } catch (error) {
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Cliente Não Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$swal("Cliente", "Ocorreu um erro na pesquisa.", "error");
      }
    },
  },
};
</script>

<style>
</style>