<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Nome Responsável</th>
        <th scope="col">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="resps in responsibles" :key="resps.id_responsavel">
        <td>{{ resps.nome }}</td>
        <td>
          <b-button
            variant="primary"
            pill
            size="sm"
            @click="findResponsibleById(resps.id_responsavel)"
            >Editar</b-button
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {
  findResponsibles,
  findResponsibleById,
} from "../../services/ServiceResponsible";
export default {
  props: {
    idEmpresa: {
      type: String,
    },
  },
  data() {
    return {
      responsibles: [],
    };
  },
  methods: {
    async findResponsiblesByIdEmpresa() {
      const resps = await findResponsibles(this.idEmpresa);
      this.responsibles = resps;
    },

    async findResponsibleById(id) {
      const resp = await findResponsibleById(this.idEmpresa, id);
      this.$emit("responsavel", resp);
    },
  },
  created() {
    this.findResponsiblesByIdEmpresa();
  },
};
</script>

<style scoped>
</style>
