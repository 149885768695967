<template>
  <div>
    <MenuBar />
    <div class="content">
      <b-card no-body>
        <b-tabs pills card v-model="tabIndex">
          <b-tab title="Cadastros">
            <FrmCliente :clienteSelecionado="clienteSelected" />
          </b-tab>
          <b-tab title="Pesquisa">
            <b-form class="col-sm-12 row" v-on:submit.prevent>
              <b-form-group label="Pesquisa" class="col-sm-6">
                <b-form-input
                  @keyup.enter="listaClientes"
                  type="text"
                  placeholder="Nome cliente"
                  size="sm"
                  required
                  v-model="valuePesquisa"
                  @dblclick="listaClientes"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Campo" class="col-sm-4">
                <b-form-select
                  v-model="colunaPesquisa"
                  :options="tablesPesquisa"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <b-form-group class="col-sm-1">
                <template #label
                  ><b-icon-search class="ml-4"></b-icon-search
                ></template>
                <b-button variant="primary" size="sm" @click="listaClientes"
                  >Pesquisar</b-button
                >
              </b-form-group>
            </b-form>
            <div class="col-sm-11" style="overflow: auto">
              <Table
                :fields="fields"
                :dataProps="items"
                @editarCliente="editarCliente"
                :keys="keys"
              >
                <template #buttonAcoes="{ uuid }">
                  <b-button
                    size="sm"
                    pill
                    variant="primary"
                    @click="editarCliente(uuid)"
                    >Editar</b-button
                  >
                </template>
              </Table>
            </div>
            <!-- <b-table
              striped
              hover
              small="small"
              :items="items"
              :fields="fields"
              style="cursor: pointer"
              :busy="isLoading"
              ref="selectableTable"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table> -->
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import MenuBar from "../../components/menu.vue";
import FrmCliente from "../../components/forms/FormCliente.vue";
import { http } from "../../services/config";
import Table from "../../components/Table/Table.vue";
import { mapState } from "vuex";

export default {
  name: "home",
  props: {},
  components: {
    MenuBar,
    FrmCliente,
    Table,
  },

  computed: {
    ...mapState({
      uuidCliente: (state) => state.uuidCliente,
    }),
  },
  data() {
    return {
      keys: [{ key: "nome" }, { key: "cpfcnpj" }, { key: "telefone" }],
      fields: [
        { titulo: "Nome/Razão Social" },
        { titulo: "CNPJ/CPF" },
        { titulo: "Telefone" },
        { titulo: "Editar" },
      ],
      tabIndex: 0,
      // fields: [
      //   { key: "nome", sortable: true, label: "Nome" },
      //   { key: "cpfcnpj", sortable: true, label: "Cpf/Cnpj" },
      //   { key: "telefone", sortable: true, label: "Telefone" },
      // ],
      items: [],
      clienteSelected: {},
      cliente_uuid: "",
      page: 0,
      isLoading: false,
      selectMode: "single",
      selected: [],
      tablesPesquisa: [
        { text: "Nome", value: "nome" },
        { text: "Cpf/Cnpj", value: "cpfcnpj" },
        { text: "E-mail", value: "email" },
      ],
      colunaPesquisa: "nome",
      valuePesquisa: "",
    };
  },
  methods: {
    async listaClientes() {
      try {
        if (this.valuePesquisa === "") {
          this.$swal("Cliente", "Informe algo no campo de Pesquisa", "info");

          return;
        }
        this.isLoading = true;
        const url = `/customers/search/pagination?${
          this.colunaPesquisa
        }=${encodeURI(this.valuePesquisa)}`;
        const { data } = await http.get(url);
        this.items = data;
        this.isLoading = false;
      } catch (ex) {
        if (ex.response.status === 404) {
          return this.$toast.open({
            message: "Nenhum Registro Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$swal("Cliente", "Ocorreu um erro na pesquisa.", "error");
      }
    },
    async anterior() {
      this.page -= 1;
      await this.listaClientes();
    },
    async proximo() {
      this.page += 1;
      await this.listaClientes();
    },

    async editarCliente(uuid) {
      try {
        const { data } = await http.get("/customers/" + uuid);
        this.clienteSelected = data;
        this.tabIndex = 0;
      } catch (error) {
        this.$swal("Cliente", "Ocorreu um erro ao editar.", "error");
      }
    },
    onRowSelected(items) {
      if (items.length !== 0) {
        this.cliente_uuid = items[0].uuid;
        this.$refs.selectableTable.clearSelected();
        this.tabIndex = 0;
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.content {
  margin: 10px 20px;
}
</style>
