<template>
  <div>
    <MenuBar />

    <div class="contentTabs">
      <b-card no-body>
        <b-tabs v-model="tabIndex" card>
          <b-tab title="Cadastro">
            <b-card>
              <FormaLiberacoes
                :liberacaoProps="liberacaoSelected"
                @chaveAdicionada="editarLiberacao($event)"
                @clearCustomer="clearLiberacao"
              />
            </b-card>
          </b-tab>
          <b-tab title="Planos">
            <b-card>
              <FormPlanos
                :idCustomer="liberacaoSelected.clienteId"
                :dataCustomer="liberacaoSelected.cliente"
                :isClearPlans="clearPlans"
              />
            </b-card>
          </b-tab>
          <b-tab title="Relatórios de planos">
            <b-card>
              <FormRelatoriosPlanos />
            </b-card>
          </b-tab>
          <b-tab title="Pesquisar">
            <b-card>
              <b-row>
                <b-form-group label="Pesquisar" class="col-sm-5">
                  <b-form-input
                    @keyup.enter="listLiberacoes"
                    size="sm"
                    v-model="valuePesquisa"
                    @dblclick="listLiberacoes"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group label="Filtro" class="col-sm-5">
                  <b-form-select
                    v-model="filtroSelected"
                    :options="filtro"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
                <b-form-group class="col-sm-1">
                  <template #label
                    ><b-icon-search class="ml-4"></b-icon-search
                  ></template>
                  <b-button variant="primary" size="sm" @click="listLiberacoes"
                    >Pesquisar</b-button
                  >
                </b-form-group>
              </b-row>
              <b-card style="overflow: auto">
                <Table :keys="keys" :fields="fields" :dataProps="liberacoes">
                  <template #buttonAcoes="{ uuid }">
                    <b-button
                      size="sm"
                      pill
                      variant="primary"
                      @click="editarLiberacao(uuid)"
                      >Editar</b-button
                    >
                  </template>
                </Table>
              </b-card>
            </b-card>
            <nav aria-label="Page navigation example" class="mt-4">
              <ul class="pagination" id="paginacao">
                <li class="page-item1 mr-3">
                  <b-button
                    class="button-previous"
                    type="button"
                    id="AnteriorPagina"
                    @click="previousPage"
                    :disabled="page === 1 ? true : false"
                  >
                    <b-icon-arrow-left-square-fill
                      class="ml-2"
                      scale="1.5"
                      style="cursor: pointer"
                      variant="primary"
                    ></b-icon-arrow-left-square-fill>
                  </b-button>
                </li>
                <li class="page-item">
                  <b-button
                    class="button-next"
                    type="button"
                    id="ProximoPagina"
                    @click="nextPage"
                  >
                    <b-icon-arrow-right-square-fill
                      scale="1.5"
                      style="cursor: pointer"
                      variant="primary"
                    ></b-icon-arrow-right-square-fill>
                  </b-button>
                </li>
              </ul>
            </nav>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import MenuBar from "../../components/menu.vue";
import Table from "../../components/Table/Table.vue";
import FormaLiberacoes from "../../components/forms/FormLiberacoes.vue";
import FormPlanos from "../../components/forms/FormPlanos.vue";
import FormRelatoriosPlanos from "../../components/forms/FormRelatoriosPlanos.vue";
import { http } from "../../services/config";

export default {
  components: {
    MenuBar,
    FormaLiberacoes,
    Table,
    FormPlanos,
    FormRelatoriosPlanos,
  },

  data() {
    return {
      tabIndex: 0,
      liberacoes: [],
      liberacaoSelected: {},
      keys: [{ key: "nome" }, { key: "cpfcnpj" }, { key: "nomesoftware" }],
      fields: [
        { titulo: "Nome/Razão Social" },
        { titulo: "CNPJ/CPF" },
        { titulo: "Software" },
        { titulo: "Editar" },
      ],
      valuePesquisa: "",
      filtroSelected: "nome",
      filtro: [
        { text: "Nome Cliente", value: "nome" },
        { text: "CNPJ / CPF", value: "cpfcnpj" },
        { text: "Software", value: "nomesoftware" },
      ],
      page: 1,
      certificateExpirationTime: null,
      clearPlans: false,
    };
  },
  methods: {
    async editarLiberacao(uuid) {
      try {
        this.clearLiberacao();
        const url = `/activations/${uuid}`;
        const { data } = await http.get(url);
        data.datainiciouso = new Date(data.datainiciouso)
          .toISOString()
          .split("T")[0];
        data.datafimuso = new Date(data.datafimuso).toISOString().split("T")[0];

        this.liberacaoSelected = data;
        this.tabIndex = 0;
      } catch (error) {
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Nenhum Registro Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$toast.open({
          message: "erro ao editar liberações",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async listLiberacoes() {
      try {
        this.page = 1;
        const url = `/activations/search/pagination?${this.filtroSelected}=${this.valuePesquisa}&page=${this.page}`;
        const { data } = await http.get(url);
        this.liberacoes = data;
      } catch (error) {
        if (error.response.status === 404) {
          this.pageBoolean = true;
          return this.$toast.open({
            message: "Nenhum Registro Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$toast.open({
          message: "erro ao pesquisar liberações",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async pagination() {
      try {
        const url = `/activations/search/pagination?${this.filtroSelected}=${this.valuePesquisa}&page=${this.page}`;
        const { data } = await http.get(url);
        this.liberacoes = data;
      } catch (error) {
        if (error.response.status === 404) {
          this.page -= 1;
          this.pageBoolean = true;
          return this.$toast.open({
            message: "Nenhum Registro Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$toast.open({
          message: "erro ao pesquisar liberações",
          type: "error",
          // all of other options may go here
        });
      }
    },

    async nextPage() {
      this.page += 1;
      this.pagination();
    },

    async previousPage() {
      this.page -= 1;
      this.pagination();
    },

    clearLiberacao() {
      this.liberacaoSelected = {};
      this.clearPlans = !this.clearPlans;
    },
  },
};
</script>

<style>
@media (max-width: 700px) {
}
.contentTabs {
  margin: 10px;
}

.button-previous {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.button-next {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}
</style>
