import * as mutationsType from './mutations-types'

export default {
    setUuidCliente({commit}, uuidCliente){
        return commit(mutationsType.uuidCliente, uuidCliente)
    },
     setClientes({commit}, clientes){
         return commit(mutationsType.clientes, clientes)
     },
     setSoftwares({commit}, softwares){
         return commit(mutationsType.softwares, softwares)
     },
}