<template>
  <b-form>
    <b-form-input type="text" hidden v-model="software.uuid"></b-form-input>
    <b-form-group id="input-group-1" label="Software">
      <b-form-input
        id="input-1"
        type="text"
        placeholder="Software"
        required
        v-model="software.nomesoftware"
      ></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-1" label="Link">
      <b-form-input
        id="input-1"
        type="text"
        placeholder="Link"
        required
        v-model="software.linkamazon"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-1" label="Versão">
      <b-form-input
        id="input-1"
        type="text"
        placeholder="Versão"
        required
        class="col-sm-2"
        v-model="software.versao"
      ></b-form-input>
    </b-form-group>
    <div class="mt-5">
      <b-button variant="info" @click="save" size="sm">Salvar</b-button>
      <b-button variant="info" size="sm" class="ml-2" @click="limpar">Limpar</b-button>
    </div>
  </b-form>
</template>

<script>
import { http } from "../../services/config";
export default {
    props:{
        softwareProp:{
            type: Object
        }
    },
  data() {
    return {
      software: {
        uuid: "",
        nomesoftware: "",
        linkamazon: "",
        versao: "",
      },
    };
  },

  watch:{
      softwareProp(){
          Object.assign(this.software, this.softwareProp)
      }
  },

  methods: {
    limpar(){
      this.software = {
        uuid: "",
        nomesoftware: "",
        linkamazon: "",
        versao: "",
      }
    },
    async save() {
      try {
        if(this.software.uuid){
        const url = `/softwares/${this.software.uuid}`;
        await http.put(url, this.software);  
        this.$swal("Software", "Software Atualizado com Sucesso", "success");
        return
        }
        const url = "/softwares";
        const { data } = await http.post(url, this.software);
        this.software.uuid = data.uuid 
        this.$swal("Software", "Software salvo com Sucesso", "success");
      } catch (error) {
          if (error.response.data.erros.length > 0) {
          error.response.data.erros.map((el) => {
            if (Object.keys(el).toString() === "nomesoftware") {
              this.$toast.open({
                message: "Software : " + el.nomesoftware,
                type: "error",
                // all of other options may go here
              });
            }
            if (Object.keys(el).toString() === "linkamazon") {
              this.$toast.open({
                message: "Link do Sistema : " + el.linkamazon,
                type: "error",
                // all of other options may go here
              });
            }
            if (Object.keys(el).toString() === "versao") {
              this.$toast.open({
                message: "Versão do Sistema : " + el.versao,
                type: "error",
                // all of other options may go here
              });
            }
          });
          return;
        }
      }
    },
  },
};
</script>

<style>
</style>