<template>
  <b-card>
    <b-row>
      <b-form-group label="Pesquisa" class="col-sm-4">
        <b-input
          @keyup.enter="listAgendamentos"
          size="sm"
          @dblclick="listAgendamentos"
          v-model="pesquisa"
        ></b-input>
      </b-form-group>
      <b-form-group label="Critério" class="col-sm-2">
        <b-form-select
          :options="campoOptions"
          size="sm"
          v-model="campo"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Status" class="col-sm-3">
        <b-form-select
          v-model="status"
          :options="statusOptions"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-row>
    <h5 class="p-2 text-center text-primary" v-if="notFound != ''">
      {{ notFound }} <b-icon-journal-x></b-icon-journal-x>
    </h5>
    <div class="tableResponsive">

    <table class="table table-sm ">
      <thead>
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">CNPJ/CPF</th>
          <th scope="col">Tipo de Suporte</th>
          <th scope="col">Data Agendamento</th>
          <th scope="col">Atendente</th>
          <th scope="col">Editar</th>
          <th scope="col">Retirar da Agenda do atendente</th>
          <th scope="col">Duplicar Agendamento</th>
          <th scope="col">Excluir</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="agenda in agendamentos" :key="agenda.uuid">
          <td>{{ agenda.nome }}</td>
          <td>{{ agenda.cpfcnpj }}</td>
          <td>{{ agenda.tipoSuporte }}</td>
          <td>{{ agenda.dataAgendamento }}</td>
          <td>{{ agenda.username }}</td>
          <td>
            <b-button
              size="sm"
              variant="primary"
              @click="editarAgendamento(agenda.uuid)"
              >Editar</b-button
            >
          </td>
          <td>
            <b-button
              size="sm"
              variant="primary"
              @click="removerAgendamento(agenda.uuid)"
              >Retirar da Agenda do(a) {{ agenda.username }}</b-button
            >
          </td>
          <td>
            <b-button
              size="sm"
              variant="primary"
              @click="duplicate(agenda.uuid)"
              >Duplicar Atendimento</b-button
            >
          </td>
          <td>
            <b-button size="sm" variant="danger" @click="deleteSchedules(agenda.uuid)"><b-icon-trash></b-icon-trash></b-button>
            </td>
        </tr>
      </tbody>
    </table>
    </div>

    <!-- <Table :fields="fields" :keys="keys" :dataProps="agendamentos">
      <template #buttonAcoes="{ uuid }">
        <b-button
          variant="primary"
          pill
          size="sm"
          @click="editarAgendamento(uuid)"
          >Editar</b-button
        >
      </template>
    </Table> -->
    <b-button
      size="sm"
      variant="info"
      class="mr-2"
      @click="nextPage"
      :disabled="disableNotFound"
      >Proxima Página <b-icon-arrow-right class="ml-2"></b-icon-arrow-right
    ></b-button>
    <b-button size="sm" variant="info" @click="PreviusPage"
      ><b-icon-arrow-left class="mr-2"></b-icon-arrow-left>Página
      Anterior</b-button
    >
  </b-card>
</template>

<script>
import { http } from "../../services/config";
// import Table from "../Table/Table.vue";
export default {
  props: {
    newAgendamento: {
      type: Boolean,
    },
  },
  components: {
    // Table,
  },
  data() {
    return {
      notFound: "",
      page: 1,
      campoOptions: [
        { text: "Nome", value: "nome" },
        { text: "CNPJ/CPF", value: "cpfcnpj" },
        { text: "Software", value: "nomesoftware" },
        { text: "Atendente", value: "username" },
      ],
      statusOptions: [
        { text: "Em Espera", value: "Espera" },
        { text: "Em Atendimento", value: "EmAtendimento" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "Em Espera sem Técnico", value: "EsperaSemTecnico" }
      ],
      horarios: {},
      disableNotFound: false,
      pesquisa: "",
      campo: "nome",
      status: "Espera",
      agendamentos: [],
      keys: [
        { key: "nome" },
        { key: "cpfcnpj" },
        { key: "dataInicioAtendimento" },
        { key: "dataFimAtendimento" },
        { key: "username" },
      ],
      fields: [
        { titulo: "Nome/Razão Social" },
        { titulo: "CNPJ/CPF" },
        { titulo: "Início" },
        { titulo: "Fim Atendimento" },
        { titulo: "Atendente" },
        { titulo: "Editar" },
      ],
    };
  },
  methods: {
   async deleteSchedules(uuid){
     try {
         await http.delete(`/schedules/delete/${uuid}`);
         await this.listAgendamentos();
       this.$toast.open({
          message: "Agendamento Deletado com Sucesso",
          type: "success",
          // all of other options may go here
        });
     } catch (error) {
        this.$toast.open({
          message: "erro ao deletar agendamento",
          type: "error",
          // all of other options may go here
        });
     }

    },
    async duplicate(uuid) {
      try {
        const { data } = await http.post(`/schedules/duplicate/${uuid}`, {});
        await this.editarAgendamento(data.uuid);
        this.$toast.open({
          message: "Agendamento Duplicado com Sucesso",
          type: "success",
          // all of other options may go here
        });
        this.agendamentos = [];
      } catch (error) {
        if (error.response.status === 400) {
          return this.$toast.open({
            message: error.response.data.message,
            type: "error",
            // all of other options may go here
          });
        }
      }
    },

    async removerAgendamento(uuid) {
      try {
        if (!uuid) {
          return this.$toast.open({
            message: "Selecione um Registro",
            type: "error",
            // all of other options may go here
          });
        }
        const url = `/schedules/remove/${uuid}`;
        await http.put(url);
        await this.listAgendamentos();
        this.$toast.open({
          message: "Agendamento Removido",
          type: "success",
          // all of other options may go here
        });
      } catch (error) {
        if (error.response.status === 400) {
          return this.$toast.open({
            message: error.response.data.message,
            type: "error",
            // all of other options may go here
          });
        }
      }
    },
    async editarAgendamento(uuid) {
      try {
        const url = `/schedules/${uuid}`;
        const { data } = await http.get(url);
        this.horarios = {
          horaAgendamento: data?.dataAgendamento?.split(" ")[1],
          horaInicio: data?.dataInicioAtendimento?.split(" ")[1],
          horaFim: data?.dataFimAtendimento?.split(" ")[1],
        };
        data.dataAgendamento = data.dataAgendamento
          ? new Date(data.dataAgendamento).toISOString().split("T")[0]
          : "";
        data.dataFimAtendimento = data.dataFimAtendimento
          ? new Date(data.dataFimAtendimento).toISOString().split("T")[0]
          : "";
        data.dataInicioAtendimento = data.dataInicioAtendimento
          ? new Date(data.dataInicioAtendimento).toISOString().split("T")[0]
          : "";

        this.$emit("dataAgendamento", { data: data, horario: this.horarios });
      } catch (error) {
        this.$swal(
          "Agendamento",
          "Ocorreu um erro ao editar agendamento",
          "error"
        );
      }
    },

    async nextPage() {
      try {
        this.page += 1;
        this.disableNotFound = false;
        this.agendamentos = [];
        const url = `/schedules/search/pagination?coluna=${this.campo}:${this.pesquisa}&status=${this.status}&page=${this.page}`;
        const { data } = await http.get(url);
        this.agendamentos = data;
      } catch (error) {
        if (error.response.status === 404) {
          this.disableNotFound = true;
          return this.$toast.open({
            message: "Nenhum Agendamento Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$swal("Agendamento", "Erro ao pesquisar", "error");
      }
    },

    async PreviusPage() {
      try {
        if (this.page > 1) {
          this.disableNotFound = false;
          this.page -= 1;
          this.agendamentos = [];
          const url = `/schedules/search/pagination?coluna=${this.campo}:${this.pesquisa}&status=${this.status}&page=${this.page}`;
          const { data } = await http.get(url);
          this.agendamentos = data;
        }
      } catch (error) {
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Nenhum Agendamento Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$swal("Agendamento", "Erro ao pesquisar", "error");
      }
    },

    async listAgendamentos() {
      try {
        if (this.campo === "" || this.status === "") {
          this.$swal(
            "Agendamento",
            "Verifique se todos campo de Pesquisa estão preenchidos",
            "info"
          );
          return;
        }
        if (sessionStorage.getItem("setor") != "Adm") {
          this.$swal(
            "Agendamento",
            "Você Não tem Autorização para essa pesquisa",
            "info"
          );
          return;
        }
        this.notFound = "";
        this.agendamentos = [];
        const url = `/schedules/search/pagination?coluna=${this.campo}:${
          this.pesquisa
        }&status=${this.status}&page=${1}`;
        const { data } = await http.get(url);
        this.agendamentos = data;
         this.agendamentos.forEach((element) => {
          switch (element.tipoSuporte) {
            case "ERROSISTEMA":
              element.tipoSuporte = "Erro Sistema";
              break;
            case "ERRONFE":
              element.tipoSuporte = "Erro NF-e";
              break;
            case "ERROCONFIGURACAO":
              element.tipoSuporte = "Erro Configuração";
              break;
            case "ERRONFCE":
              element.tipoSuporte = "Erro NFC-e";
              break;
            case "ERROMDF":
              element.tipoSuporte = "Erro MDF-e";
              break;
            case "ERRO_SISTEMA_NAO_ABRE":
              element.tipoSuporte = "Erro Sistema não Abre";
              break;
            case "ERRO_SISTEMA_PROGRAMACAO":
              element.tipoSuporte = "Erro Sistema Programação";
              break;
            case "RELATORIO_GO":
              element.tipoSuporte = "Relatório - GO";
              break;
            case "RELATORIO_RS":
              element.tipoSuporte = "Relatório - RS";
              break;
            case "RELATORIO_ES":
              element.tipoSuporte = "Relatório - ES";
              break;
            case "RELATORIO_MT":
              element.tipoSuporte = "Relatório - MT";
              break;
            case "RELATORIO_SC":
              element.tipoSuporte = "Relatório - SC";
              break;
            default:
              break;
          }
        });
        console.log(this.agendamentos)
      } catch (error) {
        if (error.response.status === 404) {
          this.notFound = "Nenhum Agendamento Encontrado";
          return;
        }
        this.$swal(
          "Agendamento",
          "Ocorreu um erro ao listar Agendamentos",
          "error"
        );
      }
    },
  },

  watch: {
    newAgendamento(){
      this.agendamentos = []
    }
  },
};
</script>

<style>
.tableResponsive{
  overflow: auto;
}
</style>