<template>
  <b-modal id="modal-lg" size="xl" title="Histórico de Suporte">
    <b-tabs pills card>
      <b-tab title="Histórico" active>
        <Table :keys="keys" :fields="fields" :dataProps="history">
          <template #buttonAcoes="{ uuid }">
            <b-button
              variant="primary"
              pill
              size="sm"
              @click="visualizarHistorico(uuid)"
              ><b-icon-eye-fill></b-icon-eye-fill> Visualizar</b-button
            >
          </template>
        </Table>
      </b-tab>
      <b-tab title="Histórico Gestão Legado">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Nome Cliente</th>
              <th scope="col">CNPJ/CPF</th>
              <th scope="col">Data Agendamento</th>
              <th scope="col">Data Início</th>
              <th scope="col">Data Fim Atendimento</th>
              <th scope="col">Nome Pessoa Atendida</th>
              <th scope="col">Técnico</th>
              <th scope="col">Observação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="histoLeg in historyLegado" :key="histoLeg.cpfcnpj">
              <th>{{histoLeg.nome}}</th>
              <td>{{histoLeg.cpfcnpj}}</td>
              <td>{{histoLeg.dataagendamento}}</td>
              <td>{{histoLeg.datainicioatendimento}}</td>
              <td>{{histoLeg.datafimatendimento}}</td>
              <td>{{histoLeg.nomepessoaatendimento}}</td>
              <td>{{histoLeg.username}}</td>
              <td>{{histoLeg.descricaoatendimento}}</td>
            </tr>
          </tbody>
        </table>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import Table from "../../components/Table/Table.vue";
export default {
  components: {
    Table,
  },
  props: {
    history: {
      type: Array,
    },
    historyLegado: {
      type: Array,
    },
  },
  data() {
    return {
      keys: [
        { key: "cpfcnpj" },
        { key: "tipoSuporte" },
        { key: "dataAgendamento" },
        { key: "dataInicioAtendimento" },
        { key: "dataFimAtendimento" },
        { key: "observacaoAtendimento" },
      ],
      fields: [
        { titulo: "CNPJ/CPF" },
        { titulo: "Tipo Suporte" },
        { titulo: "Data Agendamento" },
        { titulo: "Início Atendimento" },
        { titulo: "Fim Atendimento" },
        { titulo: "Descrição da Baixa" },
        { titulo: "Visualizar" },
      ],
    };
  },

  methods: {
    visualizarHistorico(uuid) {
      this.$emit("historicoUuid", uuid);
    },
  },
};
</script>

<style>
</style>