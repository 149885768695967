<template>
  <div>
    <b-overlay no-wrap :show="show" opacity="0.95"> </b-overlay>
    <b-row>
      <b-form-group label="Cliente" class="col-sm-5">
        <template #label>
          Cliente
          <b-icon-search
            class="ml-2"
            variant="primary"
            @click="searchCliente"
            style="cursor: pointer"
          ></b-icon-search>

          <b-icon-clock-history
            class="ml-4"
            variant="primary"
            style="cursor: pointer"
            @click="historicoCliente"
          ></b-icon-clock-history>

          <b-icon
            class="ml-4"
            icon="exclamation-circle-fill"
            variant="dark"
            @click="infoCliente"
          ></b-icon>
        </template>
        <b-form-select
          v-model="agendamento.clienteId"
          :options="clientes"
          size="sm"
          value-field="uuid"
          text-field="nome"
          @change="verificaClienteBloqueado"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Usuário" class="col-sm-3">
        <b-form-select
          v-model="agendamento.userId"
          :options="users"
          size="sm"
          value-field="uuid"
          text-field="username"
        >
          <template #first>
            <b-form-select-option :value="null">
              ---- Selecione ----</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Tipo Suporte" class="col-sm-3">
        <b-form-select
          v-model="agendamento.tipoSuporte"
          :options="optionTipoSuporte"
          size="sm"
        >
          <template #first>
            <b-form-select-option :value="null">
              ---- Selecione ----</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
    </b-row>

    <b-row>
      <b-form-group label="Data Agendamento" class="col-sm-2">
        <b-input type="date" size="sm" v-model="agendamento.dataAgendamento">
        </b-input>
        <b-form-text id="password-help-block" v-if="Loadhorario">
          Horário Agendamento :
          <label class="text-success">{{ horaAgendamento }}</label>
        </b-form-text>
      </b-form-group>
      <b-form-group label="Inicio Agendamento" class="col-sm-2">
        <b-input
          disabled
          type="date"
          size="sm"
          v-model="agendamento.dataInicioAtendimento"
        >
        </b-input>
        <b-form-text id="password-help-block" v-if="Loadhorario">
          Horário Início Atendimento :
          <label class="text-success">{{ horaInicio }}</label>
        </b-form-text>
      </b-form-group>
      <b-form-group label="Fim Agendamento" class="col-sm-2">
        <b-input
          type="date"
          size="sm"
          disabled
          v-model="agendamento.dataFimAtendimento"
        >
        </b-input>
        <b-form-text id="password-help-block" v-if="Loadhorario">
          Horário Fim Atendimento :
          <label class="text-success">{{ horaFim }}</label>
        </b-form-text>
      </b-form-group>
      <b-form-group label="Nome Pessoa atendimento" class="col-sm-3">
        <b-input
          type="text"
          size="sm"
          v-model="agendamento.nomeClienteAtendido"
        >
        </b-input>
      </b-form-group>

      <b-form-group label="Status" class="col-sm-2">
        <b-form-select
          disabled
          v-model="agendamento.status"
          :options="optionsStatus"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group label="Observação" class="col-sm-6">
        <b-form-textarea
          size="sm"
          rows="6"
          v-model="agendamento.observacao"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Descrição atendimento" class="col-sm-6">
        <b-form-textarea
          size="sm"
          rows="6"
          v-model="agendamento.observacaoAtendimento"
        ></b-form-textarea>
      </b-form-group>
    </b-row>
    <b-form-group>
      <b-button
        v-if="setor === 'Adm'"
        size="sm"
        variant="info"
        @click="saveAgendamento"
        ><b-icon-check-circle-fill class="mr-2"></b-icon-check-circle-fill>
        Agendar</b-button
      >
      <b-button size="sm" class="ml-2" variant="info" @click="limpar"
        >Novo</b-button
      >
    </b-form-group>
    <modalCliente @clienteSelecionado="agendamento.clienteId = $event" />
    <modalInfoClientes :idCliente="this.idClienteSelecionado" />
    <!-- MODAL NÃO USADO -->
    <b-modal id="modal-lg-12" size="xl" title="Histórico deee Suporte">
      <b-tabs pills card>
        <b-tab title="Histórico" active>
          <div class="containerTable">
            <Table :keys="keys" :fields="fields" :dataProps="history">
              <template #buttonAcoes="{ uuid }">
                <b-button
                  variant="primary"
                  pill
                  size="sm"
                  @click="editarAgendamento(uuid)"
                  ><b-icon-eye-fill></b-icon-eye-fill> Visualizar</b-button
                >
              </template>
            </Table>
          </div>
        </b-tab>
        <b-tab title="Histórico Gestão Legado">
          <div class="containerTable">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Nome Cliente</th>
                  <th scope="col">CNPJ/CPF</th>
                  <th scope="col">Data Agendamento</th>
                  <th scope="col">Data Início</th>
                  <th scope="col">Data Fim Atendimento</th>
                  <th scope="col">Nome Pessoa Atendida</th>
                  <th scope="col">Técnico</th>
                  <th scope="col">Observação</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(histoLeg, index) in historicoDataLegado"
                  :key="index"
                >
                  <th>{{ histoLeg.nome }}</th>
                  <td>{{ histoLeg.cpfcnpj }}</td>
                  <td>{{ histoLeg.dataagendamento }}</td>
                  <td>{{ histoLeg.datainicioatendimento }}</td>
                  <td>{{ histoLeg.datafimatendimento }}</td>
                  <td>{{ histoLeg.nomepessoaatendimento }}</td>
                  <td>{{ histoLeg.username }}</td>
                  <td>{{ histoLeg.observacao }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { http } from "../../services/config";
import Table from "../../components/Table/Table.vue";

import modalCliente from "../../components/Modals/modal-search-cliente.vue";
import modalInfoClientes from "../../components/Modals/modal-info-cliente.vue";

export default {
  props: {
    novo: {
      type: Boolean,
    },
    horarios: {
      type: Object,
    },
    agendamentoProps: {
      type: Object,
    },
  },

  created() {
    this.show = true;
    this.setor = sessionStorage.getItem("setor");
    this.listClientes();
    this.listUsers();
    this.show = false;
  },
  components: {
    modalCliente,
    Table,
    modalInfoClientes,
  },
  data() {
    return {
      idClienteSelecionado: "",
      history: [],
      historicoDataLegado: [],
      keys: [
        { key: "cpfcnpj" },
        { key: "tipoSuporte" },
        { key: "dataAgendamento" },
        { key: "dataInicioAtendimento" },
        { key: "dataFimAtendimento" },
        { key: "observacaoAtendimento" },
      ],
      fields: [
        { titulo: "CNPJ/CPF" },
        { titulo: "Tipo Suporte" },
        { titulo: "Data Agendamento" },
        { titulo: "Início Atendimento" },
        { titulo: "Fim Atendimento" },
        { titulo: "Descrição da Baixa" },
        { titulo: "Visualizar" },
      ],
      Loadhorario: false,
      horaInicio: "",
      horaFim: "",
      horaAgendamento: "",
      setor: "",
      show: false,
      clientes: [],
      statusSelected: "",
      optionsStatus: [
        { text: "Espera", value: "Espera" },
        { text: "Em Atendimento", value: "EmAtendimento" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "Em Espera sem Técnico", value: "EsperaSemTecnico" },
      ],

      optionTipoSuporte: [
        { text: "Instalação", value: "INSTALACAO" },
        { text: "Atualização", value: "ATUALIZACAO" },
        { text: "Erro no Sistema", value: "ERROSISTEMA" },
        { text: "Erro Nota Fiscal", value: "ERRONFE" },
        { text: "Erro Configuração", value: "ERROCONFIGURACAO" },
        { text: "Erro NFC-e", value: "ERRONFCE" },
        { text: "Erro MDF-e", value: "ERROMDF" },
        { text: "SICCA", value: "SICCA" },
        { text: "Sintegra", value: "SINTEGRA" },
        { text: "Dúvidas", value: "DUVIDAS" },
        { text: "Treinamento", value: "TREINAMENTO" },
        { text: "Compendio", value: "COMPENDIO" },
        { text: "Erro Sistema não abre", value: "ERRO_SISTEMA_NAO_ABRE" },
        {
          text: "Erro Sistema (Programação) ",
          value: "ERRO_SISTEMA_PROGRAMACAO",
        },
        { text: "Relatório GO (Winfit)", value: "RELATORIO_GO" },
        { text: "Relatório RS (Winfit)", value: "RELATORIO_RS" },
        { text: "Relatório ES (Winfit)", value: "RELATORIO_ES" },
        { text: "Relatório MT (Winfit)", value: "RELATORIO_MT" },
        { text: "Relatório SC (Winfit)", value: "RELATORIO_SC" },
        { text: "SAT", value: "SAT" },
        { text: "Instalar e gerar certificado", value: "INSTALAR_GERAR_CERTIFICADO" },
      ],
      agendamento: {
        uuid: "",
        clienteId: "",
        userId: null,
        dataAgendamento: new Date().toISOString().split("T")[0],
        dataInicioAtendimento: "",
        dataFimAtendimento: "",
        nomeClienteAtendido: "",
        status: "Espera",
        tipoSuporte: null,
        observacao: "",
        observacaoAtendimento: "",
      },
      users: [],
    };
  },

  methods: {
    async editarAgendamento(uuid) {
      try {
        const url = `/schedules/${uuid}`;
        const { data } = await http.get(url);

        (this.horaAgendamento = data?.dataAgendamento?.split(" ")[1]),
          (this.horaInicio = data?.dataInicioAtendimento?.split(" ")[1]),
          (this.horaFim = data?.dataFimAtendimento?.split(" ")[1]),
          (data.dataAgendamento = data.dataAgendamento
            ? new Date(data.dataAgendamento).toISOString().split("T")[0]
            : "");
        data.dataFimAtendimento = data.dataFimAtendimento
          ? new Date(data.dataFimAtendimento).toISOString().split("T")[0]
          : "";
        data.dataInicioAtendimento = data.dataInicioAtendimento
          ? new Date(data.dataInicioAtendimento).toISOString().split("T")[0]
          : "";
        Object.assign(this.agendamento, data);
        this.$bvModal.hide("modal-lg-12");
      } catch (error) {
        this.$swal(
          "Agendamento",
          "Ocorreu um erro ao editar agendamento",
          "error"
        );
      }
    },
    async historicoCliente() {
      try {
        if (this.agendamento.clienteId === "") {
          return this.$toast.open({
            message: "Selecione o Cliente por favor",
            type: "info",
            // all of other options may go here
          });
        }
        const { data } = await http.get(
          "/customers/" + this.agendamento.clienteId
        );
        await this.historico2(data.cpfcnpj);
        await this.historicoLegado2(data.cpfcnpj);
        this.$bvModal.show("modal-lg-12");
        this.tabIndex = 0;
      } catch (error) {
        this.$swal("Cliente", "Ocorreu um erro ao editar.", "error");
      }
    },
    async historico2(cpfcnpj) {
      try {
        const url = `/schedules/search/historySchedules/${cpfcnpj}`;
        const { data } = await http.get(url);
        this.history = data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$toast.open({
            message: "Nenhum Histórico Encontrado, verifique o Gestão Legado",
            type: "info",
            // all of other options may go here
          });
          return;
        }
        this.$swal("Agendamento", "Erro ao visualizar Historico", "error");
      }
    },
    async historicoLegado2(cpfcnpj) {
      try {
        const urlLegado = `/gestao/legado/historico/${cpfcnpj}`;
        const { data } = await http.get(urlLegado);
        this.historicoDataLegado = data;
   
        this.historicoDataLegado.map((el) => {
          el.dataagendamento =
            el.dataagendamento === null
              ? ""
              : new Date(el.dataagendamento).toISOString().split("T")[0];
          el.datafimatendimento =
            el.datafimatendimento === null
              ? ""
              : new Date(el.datafimatendimento).toISOString().split("T")[0];
          el.datainicioatendimento =
            el.datainicioatendimento === null
              ? ""
              : new Date(el.datainicioatendimento).toISOString().split("T")[0];
        });
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 404) {
          return this.$toast.open({
            message: "Nenhum Histórico Encontrado",
            type: "info",
            // all of other options may go here
          });
        }
        this.$toast.open({
          message: "Erro no historico legado",
          type: "info",
          // all of other options may go here
        });
      }
    },
    async removerAgendamento() {
      try {
        if (this.agendamento.uuid === "") {
          this.$toast.open({
            message: "Selecione um Registro",
            type: "error",
            // all of other options may go here
          });
          return;
        }
        const url = `/schedules/remove/${this.agendamento.uuid}`;
        await http.put(url);
      } catch (error) {
        this.$toast.open({
          message: "Erro ao remover",
          type: "error",
          // all of other options may go here
        });
      }
    },
    searchCliente() {
      this.$bvModal.show("modal-1");
    },
    infoCliente() {
      this.idClienteSelecionado = this.agendamento.clienteId;
      this.$bvModal.show("modal-info-clientes");
    },
    limpar() {
      (this.Loadhorario = false),
        (this.horaInicio = ""),
        (this.horaFim = ""),
        (this.horaAgendamento = ""),
        (this.agendamento = {
          uuid: "",
          clienteId: "",
          userId: "",
          dataAgendamento: new Date().toISOString().split("T")[0],
          dataInicioAtendimento: "",
          dataFimAtendimento: "",
          nomeClienteAtendido: "",
          status: "Espera",
          tipoSuporte: "",
          observacao: "",
          observacaoAtendimento: "",
        });
    },
    async saveAgendamento() {
      try {
        if (this.agendamento.uuid) {
          const url = `/schedules/${this.agendamento.uuid}`;
          await http.put(url, this.agendamento);
          this.$emit("newAgendamento");
          this.$swal(
            "Agendamento",
            "Agendamento Atualizado com Sucesso",
            "success"
          );
          this.limpar();
          return;
        }
        const url = "/schedules";
        const { data } = await http.post(url, this.agendamento);
        this.agendamento.uuid = data.uuid;
        this.$emit("newAgendamento");
        this.$swal("Agendamento", "Agendamento Salvo com Sucesso", "success");
        this.limpar();
      } catch (error) {
        if (error.response.status === 400) {
          return this.$toast.open({
            message: error.response.data.message,
            type: "error",
            // all of other options may go here
          });
        }
        if (error.response.data.erros.length > 0) {
          error.response.data.erros.map((el) => {
            if (Object.keys(el).toString() === "clienteId") {
              this.$toast.open({
                message: "Cliente : " + el.clienteId,
                type: "error",
                // all of other options may go here
              });
            }
            if (Object.keys(el).toString() === "tipoSuporte") {
              this.$toast.open({
                message: "Tipo Suporte : " + el.tipoSuporte,
                type: "error",
                // all of other options may go here
              });
            }
          });
          return;
        }

        this.$swal(
          "Agendamento",
          "Ocorreu um Erro ao Salvar Agendamento",
          "error"
        );
      }
    },
    async listClientes() {
      try {
        const url = `/customers/list/combobox`;
        const { data } = await http.get(url);
        this.clientes = data;
      } catch (error) {
        this.$swal("Agendamento", "erro ao carregar customers", "error");
      }
    },
    async listUsers() {
      try {
        const url = `/users/list/combobox`;
        const { data } = await http.get(url);
        this.users = data;
      } catch (error) {
        this.$swal("Agendamento", "erro ao carregar customers", "error");
      }
    },
    async verificaClienteBloqueado() {
      const response = await http.get(
        `/activations/find/actation/${this.agendamento.clienteId}`
      );
      if (response.data && response.data.bloqueado) {
        return this.$toast.open({
          message: "Este cliente precisa de atenção",
          type: "info",
        });
      }
    },
  },

  watch: {
    novo() {
      this.limpar();
    },
    agendamentoProps() {
      this.limpar();
      Object.assign(this.agendamento, this.agendamentoProps);
    },

    horarios() {
      this.horaInicio = this.horarios.horaInicio;
      this.horaFim = this.horarios.horaFim;
      this.horaAgendamento = this.horarios.horaAgendamento;
      this.Loadhorario = true;
    },
  },
};
</script>

<style>
.containerTable {
  max-height: 400px;
  overflow: auto;
}
</style>