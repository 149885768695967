<template>
  <div>
    <div class="flexDiv">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="cardPlans">
          <div class="contentTitlePlans">
            <p class="fistTitlePlans">Planos disponíveis</p>

            <p
              class="titlesPlans titlePlanAddMore"
              role="buttom"
              @click="addPlans"
            >
              <b-icon-plus scale="1.5" />
              Adicionar planos
            </p>
          </div>

          <div style="width: 100%" v-if="payload.plans.length">
            <section class="containerPlans">
              <div
                class="contentPlans"
                v-for="(plan, index) in payload.plans"
                :key="plan.idList"
              >
                <div class="contentPlanIsActive" v-if="plan.ativo">
                  A T I V O
                </div>

                <span class="contentIconRemovePlan">
                  <p
                    class="textActions removeAction"
                    @click="removePlanItem(plan.idList, plan.uuid, index)"
                  >
                    {{ plan.isDisabled || !plan.uuid ? "Deletar" : "Cancelar" }}
                  </p>
                </span>

                <span class="contentIconConfirmPlan">
                  <p
                    v-if="!plan.uuid || !plan.isDisabled"
                    class="textActions saveAction"
                    @click="save(index)"
                  >
                    Salvar
                  </p>

                  <p
                    v-else
                    class="textActions editAction"
                    @click="activateOrDeactivate(index, plan.clienteId)"
                  >
                    {{ plan.ativo ? "Desativar" : "Ativar" }}
                  </p>
                </span>

                <div class="containerLabelAndInputs">
                  <div>
                    <div class="contentLabelAndInputs" style="width: 100%">
                      <label class="labelsPlans" for="iptPlans">Planos</label>
                      <select
                        type="number"
                        id="iptPlans"
                        class="inputsPlans"
                        placeholder="Ex: 200"
                        v-model="payload.plans[index].tipoPlano"
                        :disabled="plan.isDisabled"
                        @change="clearAmountDocuments(plan, index)"
                      >
                        <option
                          v-for="list in listPlans"
                          :value="list.value"
                          :key="list.value"
                        >
                          {{ list.text }}
                        </option>
                      </select>
                    </div>

                    <div class="contentLabelAndInputs" style="width: 100%">
                      <label class="labelsPlans" for="iptPlans"
                        >Período de atuação</label
                      >
                      <select
                        type="number"
                        id="iptPlans"
                        class="inputsPlans"
                        placeholder="Ex: 200"
                        :disabled="
                          plan.isDisabled || plan.tipoPlano === 'tester'
                        "
                        v-model="payload.plans[index].periodoAtuacao"
                      >
                        <option
                          v-for="list in listPeriodApplied"
                          :value="list.value"
                          :key="list.value"
                        >
                          {{ list.text }}
                        </option>
                      </select>
                    </div>

                    <div
                      class="contentLabelAndInputs"
                      v-if="handleEnableDocuments(index)"
                    >
                      <label class="labelsPlans" for="iptPlans"
                        >Numero de notas</label
                      >
                      <input
                        type="number"
                        id="iptPlans"
                        class="inputsPlans"
                        placeholder="Ex: 200"
                        v-model="payload.plans[index].documentosRestantes"
                        :disabled="
                          plan.isDisabled ||
                          plan.tipoPlano === 'gestao_completo'
                        "
                      />
                    </div>

                    <div class="contentLabelAndInputs">
                      <label class="labelsPlans" for="iptPlans"
                        >Data inicio</label
                      >
                      <input
                        type="date"
                        id="iptPlans"
                        class="inputsPlans"
                        placeholder="Ex: 200"
                        v-model="payload.plans[index].dataInicio"
                        :disabled="plan.isDisabled"
                      />
                    </div>
                  </div>

                  <div class="contentLabelAndInputs">
                    <span
                      class="contentDueDate"
                      v-if="
                        (plan.dataInicio && plan.tipoPlano && index < 2) ||
                        (plan.dataInicio &&
                          plan.tipoPlano &&
                          index >= 2 &&
                          plan.tipoPlano !== 'fiscal')
                      "
                    >
                      <p class="dueDateTitle">Vencimento</p>
                      <p class="dueDate">
                        {{ handleDueDate(plan, index) }}
                      </p>
                    </span>

                    <span v-else-if="plan.tipoPlano === 'fiscal'">
                      <p class="dueDate">PACOTE EXTRA</p>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section class="plansNotFound" v-else>
            <span>
              <p>Nenhum plano disponível...</p>
            </span>
          </section>
        </div>
      </div>

      <div style="width: 100%">
        <Certificado :idCustomer="idCustomer" :dataCustomer="dataCustomer" />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import Certificado from "../certificate/Certificado.vue";
import { http } from "../../services/config";
import { DateTime } from "luxon";

export default {
  components: {
    Certificado,
  },

  props: {
    idCustomer: {
      type: String,
    },
    dataCustomer: {
      type: Object,
    },
    isClearPlans: {
      type: Boolean,
    },
  },

  computed: {
    findPlans() {
      return this.dataCustomer?.planos;
    },
  },
  created() {},
  data() {
    return {
      payload: {
        plans: [],
      },
      initialStatePlan: {
        isDisabled: false,
        uuid: null,
        idList: null,
        tipoPlano: null,
        clienteId: null,
        documentosRestantes: "",
        limiteDocumentos: "",
        dataInicio: null,
        dataFim: null,
        ativo: false,
        periodoAtuacao: null,
      },
      certificate: null,
      listPlans: [
        { text: "Selecione um plano", value: null },
        { text: "Tester", value: "tester" },
        { text: "Gestão Simples", value: "gestao_simples" },
        { text: "Gestão Completo", value: "gestao_completo" },
        { text: "Gestão Personalizado", value: "gestao_personalizado" },
        { text: "Emissão Fiscal", value: "fiscal" },
      ],
      listPeriodApplied: [
        { text: "Selecione um período", value: null },
        { text: "Mensal", value: "mensal" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
      ],
    };
  },
  methods: {
    addPlans() {
      this.initialStatePlan.idList = uuidv4();
      this.payload.plans = [
        ...this.payload.plans,
        JSON.parse(JSON.stringify(this.initialStatePlan)),
      ];
    },

    async save(index) {
      try {
        const plan = this.payload.plans[index];
        const formattedPlan = this.handlePlan(plan, this.idCustomer);

        const isValid = this.validadePlan(formattedPlan);
        if (isValid && isValid.message) {
          return this.handleSwal("info", isValid.message);
        }

        await http.post("/plans", formattedPlan);
        await this.findAvailablePlans();
      } catch (error) {
        const errorMessage =
          error.response.data.message || "Ocorreu um erro ao salvar o plano";
        this.handleSwal("info", errorMessage);
      }
    },

    async removePlanItem(idList, uuid, index) {
      if (uuid && !this.payload.plans[index].isDisabled) {
        this.payload.plans = this.payload.plans.map((item, i) => {
          if (index === i) {
            item.isDisabled = true;
          }

          return item;
        });

        return;
      }

      if (uuid) {
        await http.delete(`/plans/${uuid}`);
      }

      this.payload.plans = this.payload.plans.filter(
        (item) => item.idList !== idList
      );
    },

    async findAvailablePlans() {
      try {
        const { data } = await http.get(
          `/plans/available-plans/${this.idCustomer}`
        );

        if (data && data.length) {
          const newPlans = data.map((item) => {
            const idList = uuidv4();

            return {
              ...item,
              idList,
              isDisabled: true,
              dataInicio: DateTime.fromISO(item.dataInicio)
                .toLocal()
                .toFormat("yyyy-MM-dd"),
              dataFim: DateTime.fromISO(item.dataFim)
                .toLocal()
                .toFormat("yyyy-MM-dd"),
            };
          });

          this.payload.plans = newPlans;
        }
      } catch (error) {
        this.handleSwal(
          "info",
          "Ocorreu um erro ao buscar os planos disponíveis"
        );
      }
    },

    handlePlan(plan, idCustomer) {
      const newPlan = JSON.parse(JSON.stringify(plan));
      newPlan.dataInicio = DateTime.fromISO(plan.dataInicio)
        .toLocal()
        .toFormat("yyyy-MM-dd");

      if (this.payload.plans.length > 2 && plan.tipoPlano === "fiscal") {
        newPlan.dataFim = null;
      } else {
        newPlan.dataFim = DateTime.fromSQL(plan.dataFim)
          .toLocal()
          .toFormat("yyyy-MM-dd");
      }

      Reflect.deleteProperty(newPlan, "idList");
      Reflect.deleteProperty(newPlan, "isDisabled");

      newPlan.documentosRestantes = parseInt(plan.documentosRestantes);
      newPlan.limiteDocumentos = parseInt(plan.documentosRestantes);
      newPlan.clienteId = idCustomer;

      return newPlan;
    },

    async activateOrDeactivate(index, clienteId) {
      const plansNotReference = JSON.parse(JSON.stringify(this.payload.plans));
      const newPlans = plansNotReference.map((item, i) => {
        if (index === i) {
          item.ativo = !item.ativo;
        }

        return item;
      });

      const payload = {
        ativo: newPlans[index].ativo,
        tipoPlano: newPlans[index].tipoPlano,
        clienteId,
      };

      const { data } = await http.patch(
        `/plans/${newPlans[index].uuid}`,
        payload
      );

      this.payload.plans = data.map((item) => {
        return {
          ...item,
          dataInicio: DateTime.fromISO(item.dataInicio)
            .toLocal()
            .toFormat("yyyy-MM-dd"),
          dataFim: DateTime.fromISO(item.dataFim)
            .toLocal()
            .toFormat("yyyy-MM-dd"),
          idList: uuidv4(),
          isDisabled: true,
        };
      });
    },

    handleDueDate(plan, index) {
      if (plan.tipoPlano === "tester") {
        return this.handleDatePlansAndDisplay(plan, index, 1);
      } else if (
        plan.tipoPlano !== "tester" &&
        plan.periodoAtuacao === "mensal"
      ) {
        return this.handleDatePlansAndDisplay(plan, index, 1);
      } else if (plan.periodoAtuacao === "trimestral") {
        return this.handleDatePlansAndDisplay(plan, index, 3);
      } else if (plan.periodoAtuacao === "semestral") {
        return this.handleDatePlansAndDisplay(plan, index, 6);
      } else if (plan.periodoAtuacao === "anual") {
        return this.handleDatePlansAndDisplay(plan, index, 12);
      }
    },

    dateDisplay(plan, months = 1) {
      if (plan.uuid) {
        return plan.dataFim
          ? DateTime.fromSQL(plan.dataFim).toLocal().toFormat("dd/MM/yyyy")
          : DateTime.fromISO(plan.dataInicio)
              .plus({ months })
              .toLocal()
              .toFormat("dd/MM/yyyy");
      } else {
        return DateTime.fromISO(plan.dataInicio)
          .plus({ months })
          .toLocal()
          .toFormat("dd/MM/yyyy");
      }
    },

    endDatePlans(plan, index, months) {
      if (!plan.uuid)
        this.payload.plans[index].dataFim = DateTime.fromISO(plan.dataInicio)
          .plus({ months })
          .toLocal()
          .toFormat("yyyy-MM-dd");
    },

    handleDatePlansAndDisplay(plan, index, months = 1) {
      this.endDatePlans(plan, index, months);
      return this.dateDisplay(plan, months);
    },

    clearAmountDocuments(plan, index) {
      if (plan.tipoPlano === "tester")
        this.payload.plans[index].periodoAtuacao = "mensal";

      if (plan.tipoPlano === "gestao_completo") {
        this.payload.plans[index].documentosRestantes = 200;
        this.payload.plans[index].limiteDocumentos =
          this.payload.plans[index].documentosRestantes;
      } else {
        this.payload.plans[index].documentosRestantes = 0;
        this.payload.plans[index].limiteDocumentos = 0;
      }

      if (plan.tipoPlano === "fiscal")
        this.payload.plans[index].periodoAtuacao = null;
    },

    handleEnableDocuments(index) {
      if (
        this.payload.plans[index].tipoPlano !== "fiscal" &&
        this.payload.plans[index].clienteId
      ) {
        return false;
      } else {
        return (
          this.payload.plans[index].tipoPlano === "fiscal" ||
          this.payload.plans[index].tipoPlano === "gestao_completo" ||
          this.payload.plans[index].tipoPlano === "gestao_personalizado"
        );
      }
    },

    validadePlan(plan) {
      const contentError = {};

      if (!plan.clienteId)
        contentError.message =
          "Deve ser selecionado um cliente para que seja possível vincular um plano";
      else if (!plan.tipoPlano)
        contentError.message = "Nenhum plano selecionado";
      else if (!plan.dataInicio || plan.dataInicio === "Invalid DateTime")
        contentError.message = "Data de inicio deve ser informada";
      else if (!plan.periodoAtuacao)
        contentError.message = "Nenhum período de atuação selecionado";
      else if (
        plan.tipoPlano !== "tester" &&
        plan.tipoPlano !== "gestao_simples" &&
        !plan.documentosRestantes
      )
        contentError.message =
          "Deve ser informado a quantidade de documentos fiscais";

      return contentError;
    },

    openModalSearchPlans() {
      this.$bvModal.show("modal-plans");
    },

    handleSwal(typeAlert, message) {
      return this.$swal("Plano", message, `${typeAlert}`);
    },
  },

  watch: {
    isClearPlans() {
      this.payload.plans = [];
    },
    findPlans() {
      if (this.findPlans && this.findPlans?.length) {
        this.payload.plans = this.findPlans.map((plan) => {
          const idList = uuidv4();
          plan.idList = idList;

          plan.dataInicio = DateTime.fromISO(plan.dataInicio)
            .toLocal()
            .toFormat("yyyy-MM-dd");
          plan.dataFim = DateTime.fromISO(plan.dataFim)
            .toLocal()
            .toFormat("yyyy-MM-dd");

          plan.isDisabled = true;

          return plan;
        });
      }
    },
  },
};
</script>

<style scoped>
.titlesPlans {
  font-weight: 500;
  cursor: pointer;
  color: whitesmoke;
  padding: 0 0.3rem;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.53);
  font-size: 12px;
  user-select: none;
}

.titlePlanAddMore {
  background-color: rgb(0, 145, 255) !important;
}

.titlePlanAddMore:hover {
  background-color: rgb(0, 118, 208) !important;
  transition: ease-in all 0.1s;
}

.titleReportsPlan {
  background-color: rgb(172, 172, 172) !important;
  transition: ease-in all 0.1s;
}

.titleReportsPlan:hover {
  background-color: rgb(150, 150, 150) !important;
}

.containerPlans {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px;
  gap: 2rem;
}

.contentPlans {
  padding: 0.3rem;
  display: flex;
  border: dashed 1px rgba(0, 0, 0, 0.379);
  height: auto;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.337);
}

.contentIconRemovePlan {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -8px;
  top: -8px;
  background-color: white;
  cursor: pointer;
}

.contentIconConfirmPlan {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -8px;
  top: -8px;
  background-color: white;
}

.textActions {
  color: white;
  cursor: pointer;
  font-size: 11px;
  padding: 0 0.3rem;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.53);
  font-weight: 600;
}

.saveAction {
  background-color: rgb(0, 173, 0);
  transition: ease-in all 0.1s;
}

.saveAction:hover {
  background-color: green;
  transition: ease-in all 0.1s;
}

.editAction {
  background-color: rgb(0, 145, 255) !important;
  transition: ease-in all 0.1s;
}

.editAction:hover {
  background-color: rgb(0, 123, 217) !important;
}

.removeAction {
  background-color: rgb(197, 0, 0) !important;
  transition: ease-in all 0.1s;
}

.removeAction:hover {
  background-color: rgb(162, 0, 0) !important;
}

.inputsPlans {
  width: 200px;
  border: none;
  border: solid 1px rgba(0, 0, 0, 0.285);
  border-radius: 3px;
  outline: none;
  font-size: 10px;
}

.labelsPlans {
  font-size: 10px;
  margin: 0;
  font-weight: 600;
}

.cardPlans {
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.125);
  margin-bottom: 1rem;
  margin-top: 1rem;
  box-shadow: 0 0 2px 0 #0000004d;
}

.contentTitlePlans {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.fistTitlePlans {
  color: #666;
}

.plansNotFound {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 115px;
  font-size: 18px;
  color: rgb(150, 23, 23);
}

.containerLabelAndInputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5px;
}

.contentLabelAndInputs {
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentDueDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentDueDate p {
  margin: 0;
}

.dueDate {
  font-size: 12px;
  background-color: rgb(152, 17, 17);
  padding: 0 0.5rem;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentPlanIsActive {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 145, 255) !important;
  border-radius: 5px;
  writing-mode: vertical-lr;
  color: white;
  font-weight: 600;
  margin-top: 13px;
  margin-right: 7px;
}

@media (max-width: 700px) {
  .flexDiv {
    width: 100%;
  }
}
</style>
