<template>
  <div>
    <MenuBar />
    <b-card class="m-2">
      <h4>Logins efetuados na data de hoje</h4>
      <div
        v-for="log in loginDay"
        :key="log.uuid"
        class="text-center border rounded mt-1"
      >
        <label for="">
          <b>Usuário : <i class="text-success">{{ log.username }}</i>
          </b>
          <b> | {{ log.data }} | </b>
        </label>
      </div>
    </b-card>

    <b-card class="m-2">
       <h4>Logs do sistema</h4>
      <div
        v-for="log in logsData"
        :key="log.uuid"
        class="text-center border rounded mt-1"
      >
        <label for="">
          <b
            >Usuário : <i class="text-success">{{ log.username }}</i>
          </b>
          <b> | {{ log.data }} | </b>
          <b>Mensagem : </b>
          <i class="text-success mr-2">{{ log.message }}</i>
        </label>
      </div>
    </b-card>
  </div>
</template>

<script>
import MenuBar from "../../components/menu.vue";
import { http } from "../../services/config";

export default {
  components: {
    MenuBar,
  },

  data() {
    return {
      logsData: [],
      loginDay: [],
    };
  },

  created() {
    this.logs();
  },

  methods: {
    async logs() {
      const url = "/logger";
      const { data } = await http.get(url);
      this.logsData = data.loggers;
      this.loginDay = data.loggerLoginDay;
    },
  },
};
</script>

<style>
</style>