<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import HomePage from "./pages/cliente/cliente.vue";
// import socket from "../src/services/socket";


export default {
  name: "App",
  components: {
    // HomePage,
  },

  // mounted() {
  //   socket.on('connect', () => {
  //     console.log('conectado')
  //   })

  //   console.log('mouted')

  //   socket.off("send-solicitation-gestao").on('send-solicitation-gestao', () => {
  //     this.$toast.open({
  //       message: "Nova solicitação de responsavél Winfit",
  //       type: "success",
  //     });
  //   })
  // }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@500&family=Roboto:wght@300&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background-color: rgb(134, 133, 133) !important;

}

*::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(134, 133, 133);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid rgb(255, 255, 255);
  /* creates padding around scroll thumb */
}
</style>
