import axios from "axios";

export const http = axios.create({
  baseURL: process.env.VUE_APP_WINFIT_CORE,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = process.env.VUE_APP_URL_FRONT;
    }

    return Promise.reject(error);
  }
);
